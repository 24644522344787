<template>
  <div v-loading="loading" class="full-service-posts">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <h1 class="header">
        <link-button
          v-if="isStaff && property.id && company.id"
          :to="{ name: 'PropertyEdit', params: { cid: company.id, id: property.id } }"
          type="primary"
          target="_blank"
        >
          {{ property.name }} Full Service Posts
        </link-button>
        <span v-else>{{ property.name }} Agency Posts</span>
        <el-tag
          v-if="hasPremiumSubscription"
          class="tag"
          size="large"
        >
          Premium
        </el-tag>
      </h1>
      <h4 class="last-updated">
        Last Updated: {{ lastUpdated }}
      </h4>
    </el-row>
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <h3 class="subtitle">
        {{ company.human_name }}
      </h3>
      <el-button size="small" @click="fetchData">
        Refresh Page
      </el-button>
    </el-row>
    <el-row v-for="url in property.floor_plan_urls" :key="url">
      <el-button
        class="floorplan-button"
        type="text"
        @click="openUrl(url)"
      >
        {{ url }}
      </el-button>
    </el-row>
    <full-service-posts-widgets
      :data="widgetData"
      @mark-complete="markTaskComplete"
      @mark-incomplete="markTaskIncomplete"
      @mark-unable="showAddUnableReasonDialog = true"
    />
    <notes-form
      label="Company Service Notes"
      button-label="Edit Company Service Notes"
      :notes="companyNotes"
      @save="saveNotes({ company_service_notes: $event })"
    />
    <notes-form
      label="Property Service Notes"
      button-label="Edit Property Service Notes"
      :notes="propertyNotes"
      @save="saveNotes({ property_service_notes: $event })"
    />
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <h1>Craigslist Posting Region</h1>
    </el-row>
    <el-row>
      <div v-if="craigslistRegion">
        {{ craigslistRegion }}
      </div>
      <div v-else>
        There is no saved posting region for the property.
      </div>
    </el-row>
    <el-row>
      <h3>Queued & Scheduled Posts ({{ queuedPosts.length + futurePosts.length }})</h3>
      <posting-history-table
        :posts="[...queuedPosts, ...futurePosts]"
        :timezone="property.property_address && property.property_address.timezone_name"
      />
    </el-row>
    <el-row>
      <h3>Posting history for the past week ({{ pastPosts.length }})</h3>
      <posting-history-table
        :posts="pastPosts"
        :timezone="property.property_address && property.property_address.timezone_name"
      />
    </el-row>
    <incomplete-reason-dialog
      :show="showAddUnableReasonDialog"
      :reason="reason"
      :comment="comment"
      @close="showAddUnableReasonDialog = false"
      @submit="markTaskUnableToPost"
    />
  </div>
</template>

<script>
import { addWeeks, format, getDay, isToday, differenceInHours, subWeeks, parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import CraigslistAPI from '@/services/api/craigslist'
import ProblemReportsAPI from '@/services/api/problemReports'
import RooofAPI from '@/services/api/rooof'
import { getFeatureSet } from '@/utils/subscriptions'
import { enums } from '@/utils/constants'
import LinkButton from '@/components/buttons/LinkButton'

import CompanyNotesForm from '@/views/posting-groups/companies/_components/forms/CompanyNotesForm'
import FullServicePostsWidgets from './_components/FullServicePostsWidgets'
import IncompleteReasonDialog from './_components/IncompleteReasonDialog'
import PostingHistoryTable from './_components/PostingHistoryTable'

export default {
  name: 'FullServicePosts',
  components: {
    'notes-form': CompanyNotesForm,
    LinkButton,
    PostingHistoryTable,
    FullServicePostsWidgets,
    IncompleteReasonDialog
  },
  data () {
    return {
      loading: false,
      showAddUnableReasonDialog: false,
      property: {},
      craigslistProperty: {},
      craigslistRegion: '',
      company: {},
      propertyNotes: '',
      companyNotes: '',
      pastPosts: [],
      futurePosts: [],
      queuedPosts: [],
      lastUpdated: '',
      reason: '',
      comment: ''
    }
  },
  computed: {
    isStaff () {
      return this.$store.getters['auth/isStaff']
    },
    today () {
      return formatInTimeZone(new Date(), 'America/Los_Angeles', 'yyyy-MM-dd')
    },
    taskIsCompleted () {
      if (!this.property.posting_completed || !this.property.posting_completed.length) {
        return false
      }
      return isToday(parseISO(this.property.posting_completed[0]))
    },
    taskIsUnableToComplete () {
      if (!this.property.posting_incomplete_reason || !this.property.posting_incomplete_reason.length) {
        return false
      }
      if (this.property.posting_incomplete_reason[0]['timestamp'] === this.today) {
        return true
      }
      return false
    },
    numberOfFailures () {
      let count = 0
      for (const post of this.pastPosts) {
        const dateScheduled = new Date(post.scheduled_time)
        const isWithin48Hours = differenceInHours(new Date(), dateScheduled) <= 48
        if (isWithin48Hours && post.status === 'failed') {
          count++
        }
      }
      return count
    },
    dailyPostsBreakdown () {
      // 0 = sunday, 1 = monday, ...
      const days = new Array(7).fill(0)
      for (const post of this.futurePosts) {
        const day = getDay(parseISO(post.scheduled_time))
        days[day] += 1
      }
      return days
    },
    widgetData () {
      return {
        taskIsCompleted: this.taskIsCompleted,
        taskIsUnableToComplete: this.taskIsUnableToComplete,
        numberOfFailures: this.numberOfFailures,
        futurePostsCount: this.futurePosts.length,
        queuedPostsCount: this.queuedPosts.length,
        postingTarget: this.company.daily_posting_target,
        dailyPostsBreakdown: this.dailyPostsBreakdown,
        isPremium: this.hasPremiumSubscription,
        propertyId: this.property.id,
        unitUrl: this.property.all_available_units_url,
        floorPlanUrl: this.property.floor_plan_urls && this.property.floor_plan_urls[0],
        lastCompletedDate: this.property.posting_completed && this.property.posting_completed[0] &&
          format(parseISO(this.property.posting_completed[0]), 'yyyy-MM-dd'),
        lastUnableToPostDate: this.property.posting_incomplete_reason && this.property.posting_incomplete_reason[0] && this.property.posting_incomplete_reason[0]['timestamp'] &&
          this.property.posting_incomplete_reason[0]['timestamp'],
        lastUnableToPostReason: this.property.posting_incomplete_reason && this.property.posting_incomplete_reason[0] && this.property.posting_incomplete_reason[0]['reason']
      }
    },
    hasPremiumSubscription () {
      if (!this.property.features_list) {
        return false
      }
      const featureSet = getFeatureSet(this.property, status => ![enums.status.INACTIVE, enums.status.PRE_TRIAL, enums.status.PENDING, enums.status.INVALID].includes(status))
      return featureSet.has(enums.features.PREMIUM_SERVICE)
    }
  },
  created () {
    this.formatInTimeZone = formatInTimeZone
    this.fetchData()
  },
  methods: {
    /**
     * Saves notes to the API in markdown.
     *
     * @param {Object} payload
     */
    async saveNotes (payload) {
      // new api call
      try {
        await CraigslistAPI.notes.partialUpdate(this.$route.params.id, payload, false)
        this.fetchData()
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Sends a message to the chrome extension
     * with the property id which then opens a new tab
     * to the floorplans page.
     *
     * @param {String} url
     */
    openUrl (url) {
      window.postMessage({
        name: 'OpenCaaSTab',
        url: url,
        property_filter: this.widgetData.propertyId
      }, '*')
    },

    /**
     * Fetch property, company, and SPs from the API.
     */
    async fetchData () {
      try {
        this.loading = true
        this.property = await RooofAPI.properties.retrieve(this.$route.params.id)
        this.company = await RooofAPI.companies.retrieve(this.property.company)

        this.craigslistProperty = await CraigslistAPI.properties.retrieve(this.$route.params.id)
        this.propertyNotes = this.property.service_notes
        this.companyNotes = this.company.service_notes

        const futureStatuses = ['scheduled', 'pending', 'posting']
        const pastStatuses = ['unscheduled', 'successful', 'failed']
        const lastWeek = format(subWeeks(new Date(), 1), 'yyyy-MM-dd')
        const nextWeek = format(addWeeks(new Date(), 1), 'yyyy-MM-dd')

        const response = await CraigslistAPI.scheduled_posts.list({
          property: this.$route.params.id,
          date_before: nextWeek,
          date_after: lastWeek
        })
        /**
         * Build the Region info string
         */
        this.craigslistRegion = this.craigslistProperty.major_region
        if (this.craigslistProperty.sub_region) {
          this.craigslistRegion = `${this.craigslistRegion} > ${this.craigslistProperty.sub_region}`
        }
        if (this.craigslistProperty.sub_sub_region) {
          this.craigslistRegion = `${this.craigslistRegion} > ${this.craigslistProperty.sub_sub_region}`
        }

        this.pastPosts = response.filter(
          post => pastStatuses.includes(post.status)
        )
        this.futurePosts = response.filter(
          post => futureStatuses.includes(post.status)
        )

        this.queuedPosts = await CraigslistAPI.scheduled_posts.list({
          property: this.$route.params.id,
          unscheduled_posts: true
        })

        this.lastUpdated = format(new Date(), 'h:mm a, ccc MMMM d, yyyy')
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Adds a timestamp to the property's posting_completed list
     */
    async markTaskComplete () {
      try {
        this.loading = true
        const completedPosts = JSON.parse(JSON.stringify(this.property.posting_completed))
        completedPosts.unshift((new Date()).toISOString())
        const payload = { posting_completed: completedPosts }
        this.property = await RooofAPI.properties.partialUpdate(this.$route.params.id, payload, false)
        this.fetchData()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
    },
    /**
     * Removes the first entry of the property's posting_completed list or
     * the property's posting_incomplete_reason list
     */
    async markTaskIncomplete () {
      try {
        this.loading = true
        let payload = {}
        if (this.taskIsUnableToComplete) {
          const incompleteReason = JSON.parse(JSON.stringify(this.property.posting_incomplete_reason))
          incompleteReason.shift()
          payload = { posting_incomplete_reason: incompleteReason }
        } else if (this.taskIsCompleted) {
          const completedPosts = JSON.parse(JSON.stringify(this.property.posting_completed))
          completedPosts.shift()
          payload = { posting_completed: completedPosts }
        }
        this.property = await RooofAPI.properties.partialUpdate(this.$route.params.id, payload, false)
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Adds a "Unable to Post" log to the property's posting_incomplete_reason list
     */
    async markTaskUnableToPost (reason) {
      try {
        this.loading = true
        this.reason = reason.reason
        this.comment = reason.comment

        // Update posting incomplete reason on property
        const unableToPost = JSON.parse(JSON.stringify(this.property.posting_incomplete_reason))
        const postingLog = {
          'account': this.$store.state.auth.account.id,
          'timestamp': this.today,
          'reason': this.reason
        }
        unableToPost.unshift(postingLog)
        const payload = { posting_incomplete_reason: unableToPost }
        this.property = await RooofAPI.properties.partialUpdate(this.$route.params.id, payload, false)

        // send help ticket
        const problemReport = {
          'property': parseInt(this.$route.params.id),
          'reason': this.reason,
          'comment': this.comment
        }
        const res = await ProblemReportsAPI.unableToPost.create(problemReport)
        const message = {
          dangerouslyUseHTMLString: true,
          message: '',
          duration: 6500,
          showClose: true,
          type: 'success'
        }
        if (res.data) {
          const messageText = res.data.created ? 'created' : 'updated'
          message.message = `Jira ticket <a href="${res.data.link}" target="_blank">${res.data.link}</a> has been successfully ${messageText}.`
        }
        this.$message(message)
      } catch (err) {
        const details = err.response ? err.response.data : null
        if (details && err.response.status === 500 && err.response.data.error && err.response.data.error === 'Unable to log missed task to Jira') {
          this.$message({
            message: 'Jira ticket could not be created. An email has been sent to help@rooof.com for follow-up.',
            duration: 3500,
            showClose: true,
            type: 'error'
          })
        } else {
          this.$rfAlert.error(this, err.toString(), details)
        }
      } finally {
        this.showAddUnableReasonDialog = false
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.full-service-posts {
  .header {
    margin: 0;
    font-size: 2rem;
    display: flex;
    align-items: center;
    a {
      font-size: 2rem;
    }
    .tag {
      margin: 0 1em;
    }
  }
  .subtitle {
    font-weight: normal;
    margin: 0.5em 0;
  }
  .last-updated {
    margin: 0;
  }
}
.floorplan-button {
  padding: 0;
  color: #1a7dc6;
  font-style: bold;
  font-size: 14px;
}
.floorplan-button:hover {
  text-decoration: underline;
}
</style>
